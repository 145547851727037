const triggerClass = 'js-toggler-trigger'
const parentClass = 'js-toggler'
const activeClass = 'is-active'

let elements = document.getElementsByClassName(triggerClass);
Array.from(elements).forEach(function(element) {
    element.addEventListener('click', togglerInit);
});

function togglerInit() {
    let parent = findAncestor(this, parentClass)
    if (parent) {
        if (parent.classList.contains(activeClass)) {
            parent.classList.remove(activeClass)
        }
        else {
            parent.classList.add(activeClass)
        }
    }
}

function findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}